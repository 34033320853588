import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, Input } from '@angular/core';
import { ConfigurationService, WindowRef } from '@spartacus/core';
import { IconLoaderService } from '@spartacus/storefront';
import { filter, take } from 'rxjs/operators';
import { KurzIconType } from '../../../custom/custom-configuration-modules/custom-icon-types-mapping.module';
import { ColorThemableComponent } from '../shared/color-theme/theme.enum';


export enum KurzIconSize {
  EXTRA_SMALL = 'EXTRA_SMALL',
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
  EXTRA_LARGE = 'EXTRA_LARGE'
}

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconComponent implements ColorThemableComponent {

  private _iconType: KurzIconType;
  private _iconClasses: string[];
  private _iconSize: KurzIconSize;
  private _iconSizeClass: string;

  @HostBinding('class')
  private get hostClasses(): string[] {
    const arr: string[] = [
      'app-icon',
      (this._iconSizeClass || 'app-icon-medium'),
      ...(this.iconClasses || [])
    ];

    return arr;
  }

  @Input()
  set iconType(value: KurzIconType) {

    // addLinkResource() downloads the ressource file if needed
    this.iconLoader.addLinkResource(value);

    this.configuratorService.unifiedConfig$.pipe(filter(_ => !!_), take(1)).subscribe(config => {
      const str = config.icon?.symbols?.[value as string];
      this._iconClasses = typeof str === 'string' ? (str.split(' ').filter(_ => !!_)) : [];
    });
    this._iconType = value;
  }

  get iconType(): KurzIconType {
    return this._iconType;
  }

  get iconClasses(): string[] {
    return this._iconClasses;
  }

  get iconSize(): KurzIconSize {
    return this._iconSize;
  }

  @Input()
  set iconSize(value: KurzIconSize) {
    this._iconSize = value;
    this._iconSizeClass = 'app-icon-medium';
    switch(value) {
      case KurzIconSize.EXTRA_SMALL: this._iconSizeClass = 'app-icon-extra-small'; break;
      case KurzIconSize.SMALL: this._iconSizeClass = 'app-icon-small'; break;
      case KurzIconSize.LARGE: this._iconSizeClass = 'app-icon-large'; break;
      case KurzIconSize.EXTRA_LARGE: this._iconSizeClass = 'app-icon-extra-large'; break;
    }
  }

  private _superscript: string;

  get superscript(): string {
    return this._superscript;
  }

  @Input()
  set superscript(value: string) {
    this._superscript = value;
    this.updateSuperscriptProperties();
  }

  constructor(
    protected iconLoader: IconLoaderService,
    private configuratorService: ConfigurationService,
    private elementRef: ElementRef,
    private windowRef: WindowRef
  ) {}

  private updateSuperscriptProperties() {
    const host = this.elementRef?.nativeElement as HTMLElement;
    if (host) {
      const comp = this.windowRef.nativeWindow?.getComputedStyle(host);
      const prop = (parseFloat(comp.fontSize || '16px') / 8 * (this.superscript?.length || 0)) + 'px';
      host.style.setProperty('--superscript-padding', prop);
    }
  }

}
